import React from "react";

import { useApp } from "../../hooks/useApp";

import { CodeBlock } from "../code-block/CodeBlock";

import "prismjs/components/prism-bash";
import "prismjs/plugins/command-line/prism-command-line";
import "prismjs/plugins/command-line/prism-command-line.css";

export const CurlSnippet: React.FC = () => {
  const app = useApp();

  const code = `\
curl -X POST \\
  --location 'https://${app.network}.phantom-rpc.com/rpc' \\
  --header 'Content-Type: application/json' \\
  --data '{"jsonrpc": "2.0", "method": "chain_get_state_root_hash", "id": 1}'\
`;

  return (
    <CodeBlock
      className="lang-sh"
      code={code}
      preAttrs={{
        className: "command-line",
        // @ts-ignore
        "data-prompt": "$",
        "data-continuation-str": "\\",
      }}
    />
  );
};
