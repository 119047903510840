import "./Home.css";

import React, { useEffect, useState } from "react";
import { Container, Grid, Box, Typography, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useApp } from "../../hooks/useApp";
import { CardBox } from "../../components/card-box/CardBox";
import { BorderBox } from "../../components/border-box/BorderBox";
import { CopyLink } from "../../components/copy-link/CopyLink";
import { SingleSummaryInfo } from "../../components/single-summary-info/SingleSummaryInfo";
import { LiveMapHexbin } from "../../components/live-map-hexbin/LiveMapHexbin";
// import { NodesInAction } from "../../components/nodes-in-action/NodesInAction";
import { ProsList } from "../../components/pros-list/ProsList";
import { UpcomingProjects } from "../../components/upcoming-projects/UpcomingProjects";

// Icons
import { Section } from "../../layouts/landing-page/Section";
import { ConnectionCodeSnippets } from "../../components/connection-code-snippets/ConnectionCodeSnippets";
import { RequestsLogger } from "../../components/requests-logger/RequestsLogger";
// import { ReactComponent as Logo } from "../../assets/icons/ghost-black-no-lines.svg";
// import { Partners } from "../../components/partners/Partners";
// import { UpcomingProjectsButton } from "../../components/upcoming-projects-button/UpcomingProjectsButton";
import { wsManager } from "../../ws/WebSocketManager";
import PartnerHero from "../../components/partner-hero/PartnerHero";
import { EndpointTypeSwitch } from "../../components/endpoint-type-switch/EndpointTypeSwitch";

type HomeProps = {};

export const Home: React.FC<HomeProps> = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const app = useApp();
  const { t } = useTranslation();

  const [statsTotalRequest, setStatsTotalRequest] = useState<number | null>(null);
  const [statsCachedRequest, setStatsCachedRequest] = useState<number | null>(null);
  const [statsUptime, setStatsUptime] = useState<number | null>(null);

  // Live stats.
  useEffect(() => {
    wsManager.connect();

    const unsubscribe = wsManager.subscribe((data) => {
      setStatsTotalRequest(data["stats"]["total_requests"]);
      setStatsCachedRequest(data["stats"]["cached_requests"]);
      setStatsUptime(data["stats"]["uptime"]);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
      <Section maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2, py: 10, opacity: 0.9 }}>
              <Typography variant={"h1"} component={"h1"} textAlign={"center"} sx={{ fontSize: "2.5rem", mb: 1 }}>
                Casper{" "}
                <Typography
                  component={"span"}
                  variant={"h1"}
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: "2.5rem",
                  })}
                >
                  Public Endpoint
                </Typography>
              </Typography>
              <Typography component={"p"} variant={"h4"} sx={{ textAlign: "center", textWrap: "balance" }}>
                The most <span className="color-underline c-primary">reliable</span> and <span className="color-underline c-secondary">hassle-free</span> public RPC for the Casper
                blockchain.
              </Typography>
              <Typography sx={{ fontStyle: "italic", mt: 2, fontSize: "1.1rem" }}>Spend time building your dApp, not configuring nodes!</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ position: "relative", display: { xs: "none", md: "block" } }}>
            <Box className="ghost-hero">
              <img src={"/ghost_hero.png"} alt="Casper Ghost" style={{ width: "200px", height: "auto" }} className="ghost-img" />
            </Box>
          </Grid>
        </Grid>
      </Section>

      <Section maxWidth={"md"} backgroundGradient={"secondary"}>
        <EndpointTypeSwitch />
        <CardBox sx={{ mb: 1 }}>
          <CopyLink value={app.getEndpointValue()} />
        </CardBox>
      </Section>

      <Section maxWidth={"md"}>
        <CardBox color={"secondary"} sx={{ mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <BorderBox color={"secondary"}>
                <SingleSummaryInfo
                  header={"Total requests"} //
                  subheader={"since June 2024"}
                  value={statsTotalRequest || "-"}
                  hint={undefined}
                  color={"secondary"}
                  wordsToColorize={["Total"]}
                  useSeparator={true}
                />
              </BorderBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <BorderBox color={"secondary"}>
                <SingleSummaryInfo
                  header={"Cached requests"} //
                  subheader={"in total"}
                  value={(statsCachedRequest && statsCachedRequest + "%") || "-"}
                  hint={undefined}
                  color={"secondary"}
                  wordsToColorize={["Cached"]}
                />
              </BorderBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <BorderBox color={"secondary"}>
                <SingleSummaryInfo
                  header={"System uptime"} //
                  subheader={"month average"}
                  value={(statsUptime && statsUptime + "%") || "-"}
                  hint={undefined}
                  color={"secondary"}
                  wordsToColorize={["uptime"]}
                />
              </BorderBox>
            </Grid>
            {/* <Grid item xs={12}>
              <NodesInAction />
            </Grid> */}
          </Grid>
        </CardBox>
      </Section>

      <Section maxWidth={"md"}>
        <CardBox color={"primary"} sx={{ mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"h2"} sx={{ mb: theme.spacing(1) }}>
                <Typography component={"span"} variant={"h2"} color={"primary"}>
                  Live
                </Typography>{" "}
                requests location
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LiveMapHexbin sphereFillEnabled={false} sphereStrokeEnabled={false} />
            </Grid>
            <Grid item xs={12}>
              <RequestsLogger />
            </Grid>
          </Grid>
        </CardBox>
      </Section>

      <Section maxWidth={"md"}>
        <CardBox color={"primary"} sx={{ mb: 5, bgcolor: "transparent", backgroundImage: "none", boxShadow: "none", "&::before": { display: "none" } }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"h2"} textAlign={"center"} sx={{ mb: theme.spacing(1) }}>
                Why{" "}
                <Typography component={"span"} variant={"h2"} color={"primary"}>
                  you should
                </Typography>{" "}
                use it
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ProsList />
            </Grid>
          </Grid>
        </CardBox>
      </Section>

      <Section maxWidth={"md"}>
        <CardBox color={"primary"} sx={{ mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"h2"} sx={{ mb: theme.spacing(1) }}>
                Connection{" "}
                <Typography component={"span"} variant={"h2"} color={"primary"}>
                  code snippets
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ConnectionCodeSnippets />
            </Grid>
          </Grid>
        </CardBox>
      </Section>

      <Section maxWidth={"md"} id="upcoming-projects" style={{ scrollMarginTop: theme.spacing(5) }}>
        <CardBox color={"secondary"} sx={{ mb: 5, px: 0, pt: 0, border: `2px solid ${theme.palette.secondary.main}` }}>
          <Box sx={{ bgcolor: theme.palette.secondary.main, px: theme.spacing(2), py: theme.spacing(0.5) }}>
            <Typography textAlign={"center"} variant="h5" sx={{ fontWeight: 600 }}>
              UPCOMING PROJECTS
            </Typography>
          </Box>
          <Box sx={{ px: theme.spacing(2) }}>
            <Grid container spacing={2} sx={{ mt: theme.spacing(2) }}>
              {/* <Grid item xs={12}>
              <Typography component={"p"} variant={"h2"} sx={{ mb: theme.spacing(1) }}>
                Upcoming{" "}
                <Typography component={"span"} variant={"h2"} color={"primary"}>
                  projects
                </Typography>
              </Typography>
            </Grid> */}
              <Grid item xs={12}>
                <UpcomingProjects />
              </Grid>
            </Grid>
          </Box>
        </CardBox>
      </Section>

      {/* <Section maxWidth={"md"}>
        <CardBox sx={{ mb: 1, px: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"h2"} sx={{ mb: theme.spacing(1), px: 3 }}>
                Who{" "}
                <Typography component={"span"} variant={"h2"} color={"primary"}>
                  trusted
                </Typography>{" "}
                us
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Partners />
            </Grid>
          </Grid>
        </CardBox>
      </Section> */}

      <Section maxWidth={"md"}>
        <CardBox color={"primary"} sx={{ mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component={"p"} variant={"h2"} sx={{ mb: theme.spacing(1) }}>
                Our{" "}
                <Typography component={"span"} variant={"h2"} color={"primary"}>
                  leading adopter
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PartnerHero />
            </Grid>
          </Grid>
        </CardBox>
      </Section>

      <Section maxWidth={"md"} backgroundGradient={"secondary"}>
        <EndpointTypeSwitch />
        <CardBox sx={{ mb: 1 }}>
          <CopyLink value={app.getEndpointValue()} />
        </CardBox>
      </Section>
    </Container>
  );
};
