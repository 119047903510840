import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { SxProps, styled, useTheme } from "@mui/material/styles";

import "./BorderBox.css";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: "0px",
}));

type BorderBoxProps = {
  children: React.ReactNode;
  color?: "primary" | "secondary";
  ribbon?: string;
  ribbonFuture?: boolean;
  muted?: boolean;
};

export const BorderBox: React.FC<BorderBoxProps & BoxProps> = ({ children, color, ribbon, ribbonFuture, muted, sx, ...rest }) => {
  const theme = useTheme();

  let borderColor;
  switch (color) {
    case "primary":
      borderColor = theme.palette.primary.main;
      break;
    case "secondary":
      borderColor = theme.palette.secondary.main;
      break;
    default:
      borderColor = theme.palette.primary.main;
      break;
  }

  if (muted) borderColor = "rgba(255,255,255,0.5)";

  const borderSize = 1;

  const styles: SxProps = {
    position: "relative", //
    background: `linear-gradient(180deg, ${borderColor} -300%, transparent 70%)`,
    color: "white",
    "&::after": {
      content: ribbon ? `'${ribbon}'` : undefined,
    },
  };

  return (
    <StyledBox sx={{ ...styles, ...sx }} className={`${ribbon ? 'ribbon' : ''} ${ribbonFuture ? 'future' : ''}`} {...rest}>
      <Box
        sx={{
          background: borderColor,
          height: borderSize + "px",
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
        }}
      />
      <Box
        sx={{
          background: `linear-gradient(180deg, ${borderColor} 20%, transparent 90%)`,
          width: borderSize + "px",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <Box
        sx={{
          background: `linear-gradient(90deg, transparent 0%, ${borderColor} 20%, transparent 85%)`,
          height: borderSize + "px",
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
        }}
      />
      <Box
        sx={{
          background: `linear-gradient(180deg, ${borderColor} 20%, transparent 70%)`,
          width: borderSize + "px",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
        }}
      />
      {children}
    </StyledBox>
  );
};
