import React from "react";

import { CodeBlock } from "../code-block/CodeBlock";
import { useApp } from "../../hooks/useApp";

import "prismjs/components/prism-python";

export const PythonSnippet: React.FC = () => {
  const app = useApp();

  const code = `\
import requests
headers = {
  'Content-Type': 'application/json',
}
data = {
  "jsonrpc": "2.0",
  "method": "chain_get_state_root_hash",
  "id": 1
}
response = requests.post(f'https://${app.network}.phantom-rpc.com/rpc', headers=headers, json=data)
print(response.text)\
`;

  return (
    <CodeBlock
      className="lang-python"
      code={code}
      preAttrs={{ className: "line-numbers" }}
    />
  );
};
