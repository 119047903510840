// websocketManager.ts
type EventHandler = (data: any) => void;

const WS_URL = "wss://mainnet.phantom-rpc.com/ws";

class WebSocketManager {
  private static instance: WebSocketManager;
  private ws: WebSocket | null = null;
  private subscribers: EventHandler[] = [];

  private constructor() {}

  public static getInstance(): WebSocketManager {
    if (!this.instance) {
      this.instance = new WebSocketManager();
    }
    return this.instance;
  }

  connect() {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
      this.ws = new WebSocket(WS_URL);

      this.ws.onopen = () => {
        console.log("WebSocket connected");
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.subscribers.forEach((handler) => handler(data));
      };

      this.ws.onerror = (event) => {
        console.error("WebSocket error:", event);
      };

      this.ws.onclose = () => {
        console.log("WebSocket disconnected");
      };
    }
  }

  subscribe(handler: EventHandler) {
    this.subscribers.push(handler);
    return () => {
      this.subscribers = this.subscribers.filter((h) => h !== handler);
    };
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
    }
  }
}

export const wsManager = WebSocketManager.getInstance();
