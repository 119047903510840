import { createTheme, ThemeOptions } from "@mui/material/styles";

import { AppTheme } from "../types";

const theme = createTheme();

// base options

const themeBase = createTheme(theme, {
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    h2: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.8rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    h3: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.4rem",
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    h5: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    body1: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    body2: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.9rem",
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    subtitle1: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.9rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    subtitle2: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.8rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    button: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.8rem",
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    caption: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.7rem",
      fontWeight: 200,
      lineHeight: 1.3,
      letterSpacing: "0em",
    },
    overline: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "0.7rem",
      fontWeight: 200,
      lineHeight: 2,
      letterSpacing: 0.08,
      textTransform: "uppercase",
    },
  },
} as ThemeOptions);

const themeLight = createTheme(themeBase, {
  palette: {
    mode: "light",
    background: {
      paper: "#ffffff", //
      default: "#fafafa",
    },
  },
} as ThemeOptions);

const themeDark = createTheme(themeBase, {
  palette: {
    mode: "dark",
    background: {
      paper: "#040C16", //
      default: "#0e1925",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255,255,255,0.6)",
      disabled: "rgba(255,255,255,0.4)",
    },
    primary: {
      light: "#02ACEA",
      main: "#02ACEA",
      dark: "#0090c5",
      contrastText: "rgba(255,255,255,255,1)",
    },
    secondary: {
      light: "#00BBA4",
      main: "#00BBA4",
      dark: "#00BBA4",
      contrastText: "rgba(255,255,255,255,1)",
    },
    error: {
      light: "#F43B56",
      main: "#F43B56",
      dark: "#F43B56",
      contrastText: "rgba(255,255,255,255,1)",
    },
    warning: {
      light: "#FFB74D",
      main: "#FFA726",
      dark: "#F57C00",
      contrastText: "rgba(255,255,255,255,1)",
    },
    success: {
      light: "#81C784",
      main: "#66BB6A",
      dark: "#388E3C",
      contrastText: "rgba(255,255,255,255,1)",
    },
    info: {
      light: "#4FC3F7",
      main: "#29B6F6",
      dark: "#0288D1",
      contrastText: "rgba(255,255,255,255,1)",
    },
    divider: "rgba(255,255,255,0.54)",
    action: {
      active: "rgba(0,0,0,0.04)",
      hover: "rgba(0,0,0,0.15)",
      hoverOpacity: 0.04,
      selected: "rgba(0,0,0,0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0,0,0,0.26)",
      disabledBackground: "rgba(0,0,0,0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0,0,0,0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
} as ThemeOptions);

export const makeTheme = (mode: AppTheme) => {
  switch (mode) {
    case "light":
      return themeLight;
    case "dark":
      return themeDark;
    default:
      return themeBase;
  }
};
