import React from "react";
import { Box, CssBaseline, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import "./LoadingScreen.css";

type LoadingScreenProps = {
  value: number;
};

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ value }) => {
  const theme = useTheme();

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          background: theme.palette.background.default,
        }}
      >
        <Box sx={{ position: "relative", width: "200px", height: "200px" }}>
          <div className="conic"></div>
          <Box sx={{ position: "absolute", top: "9px", right: "9px", bottom: "9px", left: "9px", borderRadius: "9999px", overflow: "hidden" }}>
            <Box sx={{ position: "absolute", top: "1px", right: "1px", bottom: "1px", left: "1px", borderRadius: "9999px", bgcolor: theme.palette.primary.main }} />
            <Box
              sx={{
                position: "absolute",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
                borderRadius: "0px",
                bgcolor: theme.palette.background.default,
                transform: `translateY(${-value}%)`,
                transition: "all 0.2s ease-in-out",
              }}
            />
            <Box sx={{ position: "absolute", top: "5px", right: "5px", bottom: "5px", left: "5px", borderRadius: "9999px", bgcolor: theme.palette.background.paper }} />
          </Box>
          <Stack sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", gap: 0.5 }}>
            <Typography textAlign={"center"} variant="h1" sx={{ fontWeight: 200 }}>
              {value}%
            </Typography>
            <Typography textAlign={"center"} sx={{ fontWeight: 200 }}>
              Loading...
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
