import React from "react";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";
import { useLocalStorage } from "usehooks-ts";

import { router } from "./routes";
import { AppContextProvider } from "./context/app-context/AppContext";
import { makeTheme } from "./config/theme";
import { AppTheme } from "./types";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "flag-icons/css/flag-icons.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/styles/prism-one-dark.css";

import "./assets/styles/index.css";

type AppProps = {};

export const App: React.FC<AppProps> = () => {
  const [themeMode, setThemeMode] = useLocalStorage<AppTheme>("theme-mode", "dark");

  return (
    <HelmetProvider>
      <ThemeProvider theme={makeTheme(themeMode)}>
        <AppContextProvider themeMode={themeMode} setThemeMode={setThemeMode}>
          <RouterProvider router={router} />
        </AppContextProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
