import React, { useEffect, useState, useRef, useCallback } from "react";
import Prism from "prismjs";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

import { useApp } from "../../hooks/useApp";

type CodeBlockProps = {
  className: string;
  code: string;
  copy?: boolean;
  preAttrs?: React.HTMLProps<HTMLPreElement>;
};

export const CodeBlock: React.FC<CodeBlockProps> = ({ className, code, copy = true, preAttrs = {} }) => {
  const language = className.replace("lang-", "");
  const theme = useTheme();
  const app = useApp();

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const codeRef = useRef<HTMLDivElement>(null);

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(codeRef.current?.innerText || "No content");
      setIsCopied(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      Prism.highlightAll();
    }
  }, [app.network]);

  return (
    <div style={{ position: "relative" }}>
      <pre {...preAttrs}>
        <code className={`language-${language}`} ref={codeRef}>
          {code}
        </code>
      </pre>
      {copy && (
        <IconButton aria-label="copy" sx={{ position: "absolute", top: "5px", right: "5px" }} onClick={handleCopy} disabled={isCopied}>
          {!isCopied && <ContentCopyIcon sx={{ color: theme.palette.text.primary, opacity: 0.5, "&:hover": { opacity: 1 } }} />}
          {isCopied && <DoneIcon sx={{ color: theme.palette.success.main }} />}
        </IconButton>
      )}
    </div>
  );
};
