import * as d3 from "d3";

import { HexbinGridCenter } from "./types";

// Precomputed projection values and hexbin data - based on https://observablehq.com/@koxu1996/live-map-hexbin.
// DO NOT MODIFY!!!
//

import hexbinGridCentersFiltered from "./data/hexbinGridCentersFiltered.json";

export function getPrecomputedValues() {
  const mapSize = {
    width: 360,
    height: 180,
  };
  const targetProjection = d3
    .geoEqualEarth()
    .scale(66.50336436242334)
    .translate([180, 90]);
  const hexbinGridCenters: Array<HexbinGridCenter> = hexbinGridCentersFiltered;
  const hexbinGridRadius: number = 1.41;

  return { mapSize, targetProjection, hexbinGridCenters, hexbinGridRadius };
}
