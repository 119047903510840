import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { EndpointType } from "../../types";
import { useApp } from "../../hooks/useApp";
import { JsonRpcTabs } from "./JsonRpcTabs";
import { EventsTabs } from "./EventsTabs";
import { SpeculativeExecTabs } from "./SpeculativeExecTabs";
import { DemoServerRequest } from "../demo-server-request/DemoServerRequest";
import { capitalizeString } from "../../utils/capitalizeString";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  background: "transparent",
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(
  ({ theme }) => ({
    background: theme.palette.mode === "dark" ? theme.palette.background.default : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
    color: theme.palette.text.disabled,
    "&.MuiAccordionSummary-root.Mui-expanded": {
      color: theme.palette.text.primary,
    },
    "&:hover": {
      color: theme.palette.text.primary,
    },
    transition: "all 0.2s ease",
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
  background: "transparent",
}));

export const ConnectionCodeSnippets = () => {
  const app = useApp();

  const [expanded, setExpanded] = React.useState<EndpointType>(app.endpointType);

  const handleChange = (panel: EndpointType) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(panel);
  };

  return (
    <Box sx={{ borderRadius: "0.5rem", overflow: "hidden" }}>
      <Accordion expanded={expanded === "json-rpc"} onChange={handleChange("json-rpc")}>
        <AccordionSummary aria-controls="json-rpc-content" id="json-rpc-header">
          <Typography>{`RPC (${capitalizeString(app.network)})`}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingX: 0 }}>
          <JsonRpcTabs />
          <DemoServerRequest />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion expanded={expanded === "events-sse"} onChange={handleChange("events-sse")}>
        <AccordionSummary aria-controls="events-sse-content" id="events-sse-header">
          <Typography>{`SSE (${capitalizeString(app.network)})`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EventsTabs />
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion expanded={expanded === "speculative-exec"} onChange={handleChange("speculative-exec")}>
        <AccordionSummary aria-controls="speculative-exec-content" id="speculative-exec-header">
          <Typography>SPECULATIVE EXEC RPC</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SpeculativeExecTabs />
        </AccordionDetails>
      </Accordion> */}
    </Box>
  );
};
