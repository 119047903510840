import React from "react";

import { useApp } from "../../hooks/useApp";

import { CodeBlock } from "../code-block/CodeBlock";

import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";

export const JavaScriptSnippet: React.FC = () => {
  const app = useApp();

  const code = `\
let request = await fetch("https://${app.network}.phantom-rpc.com/rpc", {
  "headers": {
    "content-type": "application/json",
  },
  "body": JSON.stringify({
    "jsonrpc": "2.0",
    "method": "chain_get_state_root_hash",
    "id": 1
  }),
  "method": "POST"
});
let response = await request.text();
console.log(response);\
`;

  return (
    <CodeBlock
      className="lang-javascript"
      code={code}
      preAttrs={{ className: "line-numbers" }}
    />
  );
};
