import React from "react";
import { CssBaseline, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Navbar } from "../../components/navbar/Navbar";
import { Footer } from "../../components/footer/Footer";

type LandingPageType = {
  children: React.ReactNode;
  backgroundType: "solid" | "gradient";
};

export const LandingPage: React.FC<LandingPageType> = ({ children, backgroundType }) => {
  const theme = useTheme();
  const firstBackground =
    backgroundType === "solid" //
      ? theme.palette.background.default
      : `linear-gradient(110deg, ${theme.palette.background.default} 60%, ${theme.palette.secondary.main} 60%)`;
  const secondBackground =
    backgroundType === "solid" //
      ? `linear-gradient(0deg, ${theme.palette.secondary.main} -250%, transparent 50%)`
      : `linear-gradient(0deg, ${theme.palette.background.default} 0%, transparent 90%)`;

  return (
    <Box
      sx={{
        background: firstBackground,
      }}
    >
      <CssBaseline />

      <Box
        sx={{
          //
          background: secondBackground,
        }}
      >
        <Box
          sx={(theme) => ({
            minHeight: "100dvh", //
            display: "flex",
            flexDirection: "column",
            position: "relative",
          })}
        >
          <Navbar />

          <Box component={"main"} sx={{ flexGrow: 1, py: theme.spacing(8) }}>
            {children}
          </Box>

          <Footer />
        </Box>
      </Box>
    </Box>
  );
};
