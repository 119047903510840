import React from "react";
import { Divider, Grid, Stack } from "@mui/material";

import { GiShieldBash } from "react-icons/gi";
import { GrNetwork } from "react-icons/gr";
import { BiSolidNetworkChart } from "react-icons/bi";
import { BsBrowserChrome } from "react-icons/bs";
import { HiServerStack } from "react-icons/hi2";
import { BsFillCloudSunFill } from "react-icons/bs";

import { SinglePros } from "./SinglePros";

export const ProsList: React.FC = () => {
  return (
    <Stack gap={8}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SinglePros
            icon={<BsBrowserChrome />}
            header="CORS FRIENDLY" //
            text="We add wildcard CORS headers to enable direct browser connections from any website."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SinglePros
            icon={<GrNetwork />}
            header="HTTP/HTTPS SUPPORT" //
            text="Whether you need HTTP for simplicity or HTTPS for security, we provide full support for both protocols."
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SinglePros
            icon={<GiShieldBash />}
            header="DDOS PROTECTED" //
            text="Ensure uninterrupted service for your applications with our infrastructure protected by Cloudflare."
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SinglePros
            icon={<HiServerStack />}
            header="BROAD INFRASTRUCTURE" //
            text="Deploying Casper nodes in regions like Asia and South America to enhance coverage and reliability."
            ribbon="COMING SOON"
            muted={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SinglePros
            icon={<BiSolidNetworkChart />}
            header="GEOROUTING" //
            text="Lightning-fast response times by routing your requests to the closest server."
            ribbon="COMING SOON"
            muted={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SinglePros
            icon={<BsFillCloudSunFill />}
            header="MAXIMUM UPTIME" //
            text="Ensuring maximum uptime with high availability, featuring redundancy and no single point of failure."
            ribbon="IN THE FUTURE"
            ribbonFuture={true}
            muted={true}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
