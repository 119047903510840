import { useContext } from "react";

import { AppContext } from "../context/app-context/AppContext";

export const useApp = () => {
  const ctx = useContext(AppContext);

  if (ctx === undefined) throw new Error("useApp must be used within AppContextProvider");

  return ctx;
};
