import React from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { SxProps, styled, useTheme, alpha } from "@mui/material/styles";

import "./Button.css";

const StyledButton = styled(MuiButton)<MuiButtonProps>(({ theme }) => ({
  borderRadius: "0px",
  textDecoration: "none",
  textTransform: "none",
  transition: "all 0.2s ease-in-out",
  "&.Mui-disabled": {
    color: "rgba(255,255,255,0.5)",
  },
}));

type ButtonProps = {
  children: React.ReactNode;
  color?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  to?: string;
  flat?: boolean;
};

export const Button: React.FC<ButtonProps & MuiButtonProps> = ({ children, color = "primary", size = "medium", flat, sx, ...rest }) => {
  const theme = useTheme();

  const alphaValue = 0.25;
  let bgcolor: string;
  switch (color) {
    default:
    case "primary":
      bgcolor = alpha(theme.palette.primary.main, alphaValue);
      break;
    case "secondary":
      bgcolor = alpha(theme.palette.secondary.main, alphaValue);
      break;
  }

  let fontSize: string;
  switch (size) {
    default:
    case "small":
      fontSize = "0.9rem";
      break;
    case "medium":
      fontSize = "1.1rem";
      break;
    case "large":
      fontSize = "1.3rem";
      break;
  }

  const styles: any = {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    background: bgcolor,
    padding: "0.7em 1em",
    fontSize: fontSize,
    color: theme.palette.text.primary,
    fontWeight: 300,
  };

  if (flat) {
    if (color === "primary") {
      styles.background = `${theme.palette.primary.main} !important`;
      styles["&:hover"] = { background: `${theme.palette.primary.dark} !important` };
    }
    if (color === "secondary") {
      styles.background = `${theme.palette.secondary.main} !important`;
      styles["&:hover"] = { background: `${theme.palette.secondary.dark} !important` };
    }
    styles.borderRadius = "0.3rem";
    styles.fontWeight = 500;
    styles.textShadow = "1px 1px 5px black";
    styles.transition = "none";
  }

  return (
    <StyledButton className="custom-button" sx={{ ...styles, ...sx }} {...rest} disableRipple={flat}>
      {!flat && (
        <>
          <span className={`line tl ${color}`} />
          <span className={`line tr ${color}`} />
          <span className={`line bl ${color}`} />
          <span className={`line br ${color}`} />
          <span className={`line lt ${color}`} />
          <span className={`line lb ${color}`} />
          <span className={`line rt ${color}`} />
          <span className={`line rb ${color}`} />
          <span className={`line inner-top ${color}`} />
          <span className={`line inner-bottom ${color}`} />
          <span className={`line inner-left ${color}`} />
          <span className={`line inner-right ${color}`} />
        </>
      )}
      {children}
    </StyledButton>
  );
};
