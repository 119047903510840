import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { SxProps, styled, useTheme } from "@mui/material/styles";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "0.5rem",
}));

type CardBoxProps = {
  children: React.ReactNode;
  color?: "primary" | "secondary";
  highlight?: "primary" | "secondary";
};

export const CardBox: React.FC<CardBoxProps & BoxProps> = ({ children, color, highlight, sx }) => {
  const theme = useTheme();

  let selectedColor: string;
  switch (color) {
    case "primary":
      selectedColor = theme.palette.primary.main;
      break;
    case "secondary":
      selectedColor = theme.palette.secondary.main;
      break;
    default:
      selectedColor = theme.palette.primary.main;
      break;
  }

  let bgColor: string;
  switch (highlight) {
    case "primary":
      bgColor = "rgba(0, 92, 126, 1)";
      break;
    case "secondary":
      bgColor = theme.palette.background.paper;
      break;
    default:
      bgColor = theme.palette.background.paper;
      break;
  }

  let boxShadow: string;
  switch (highlight) {
    case "primary":
      boxShadow = "0 0 10px 10px #00bba4";
      break;
    case "secondary":
      boxShadow = "none";
      break;
    default:
      boxShadow = "none";
      break;
  }

  const styles: SxProps = {
    color: theme.palette.text.primary, //
    backgroundImage: "url('bg.png')",
    overflow: "hidden",
    position: "relative",
    backgroundColor: bgColor,
    boxShadow: boxShadow,
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-50%",
      left: "50%",
      width: "50%",
      height: "50%",
      borderRadius: "50%",
      background: "transparent",
      boxShadow: `0px 0px 100px 0px ${selectedColor}`,
      transform: "translateX(-50%)",
    },
  };

  return <StyledBox sx={{ ...styles, ...sx }}>{children}</StyledBox>;
};
