import { createBrowserRouter } from "react-router-dom";
import { Navigate } from "react-router-dom";

// Layouts
import { LandingPage } from "./layouts/landing-page/LandingPage";

// Pages
import { Home } from "./pages/home/Home";
import { CasperDash } from "./pages/casper-dash/CasperDash";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LandingPage backgroundType="solid">
        <Home />
      </LandingPage>
    ),
  },
  {
    path: "/casper-dash",
    element: (
      <LandingPage backgroundType="solid">
        <CasperDash />
      </LandingPage>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);
