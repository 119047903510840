import React from "react";
import Container, { ContainerProps } from "@mui/material/Container";
import { Box } from "@mui/material";
import { SxProps, styled, useTheme } from "@mui/material/styles";

const StyledContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  margin: "0 auto",
  position: "relative",
}));

type SectionProps = {
  children: React.ReactNode;
  backgroundGradient?: "primary" | "secondary";
};

export const Section: React.FC<SectionProps & ContainerProps> = ({ children, backgroundGradient, sx, ...rest }) => {
  const theme = useTheme();

  const firstColor = theme.palette.secondary.main;
  const secondColor = "transparent";

  const styles: SxProps = {
    margin: "0 auto",
    py: backgroundGradient ? "15px" : undefined,
  };

  return (
    <StyledContainer
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor: backgroundGradient ? firstColor : undefined,
        m: backgroundGradient ? "65px 0 65px 0 !important" : undefined,
        pb: backgroundGradient ? "5px !important" : undefined,
        ...sx,
      }}
    >
      {backgroundGradient && (
        <Box
          sx={{
            width: "100%",
            height: "50px",
            position: "absolute",
            top: -50,
            background: `linear-gradient(to top left, ${firstColor} 49%, ${secondColor} 51%)`,
          }}
        />
      )}
      <Container sx={{ ...styles }} {...rest}>
        {children}
      </Container>
      {backgroundGradient && (
        <Box
          sx={{
            width: "100%",
            height: "50px",
            background: `linear-gradient(to top left, ${secondColor} 49%, ${firstColor} 51%)`,
            position: "absolute",
            bottom: -50,
          }}
        />
      )}
    </StyledContainer>
  );
};
