import i18n from "i18next";
import en from "../translations/en.json";
import pl from "../translations/pl.json";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
} as const;

export const initializeTranslation = (langCode: string) => {
  i18n.use(initReactI18next).init({
    lng: langCode,
    fallbackLng: "en",
    resources,
    react: {
      useSuspense: false,
    },
  });
};
