import React from "react";

import { CodeBlock } from "../code-block/CodeBlock";

import "prismjs/components/prism-go";
import { useApp } from "../../hooks/useApp";

export const GoSnippet: React.FC = () => {
  const app = useApp();

  const code = `\
package main

import (
	"bytes"
	"fmt"
	"io"
	"net/http"
)

func main() {
	jsonData := []byte(\`{"jsonrpc": "2.0", "method": "chain_get_state_root_hash", "id": 1}\`)
	resp, err := http.Post("https://${app.network}.phantom-rpc.com/rpc", "application/json", bytes.NewBuffer(jsonData))
	if err != nil {
		panic(err)
	}
	defer resp.Body.Close()
	body, err := io.ReadAll(resp.Body)
	if err != nil {
		panic(err)
	}
	fmt.Println(string(body))
}\
`;

  return (
    <CodeBlock
      className="lang-go"
      code={code}
      preAttrs={{ className: "line-numbers" }}
    />
  );
};
