import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Hexagon from "../../assets/images/hexagon-frame.png";
import { BorderBox } from "../border-box/BorderBox";

type SingleProsProps = {
  icon?: React.ReactNode;
  header?: string;
  text?: string;
  ribbon?: string;
  ribbonFuture?: boolean;
  muted?: boolean;
};

export const SinglePros: React.FC<SingleProsProps> = ({ icon, header, text, ribbon, ribbonFuture, muted }) => {
  const theme = useTheme();

  return (
    <BorderBox
      color={"primary"}
      ribbon={ribbon}
      ribbonFuture={ribbonFuture}
      muted={muted}
      sx={{
        width: "100%", //
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        flexGrow: 1,
        transition: "all 1s ease",
        "& img": {
          transition: "all 1s ease",
        },
        "& svg": {
          transition: "all 1s ease",
        },
        "& p": {
          transition: "all 1s ease",
        },
        "& span": {
          transition: "all 1s ease",
        },
        "&:hover img": {
          opacity: "1 !important",
        },
        "&:hover svg": {
          opacity: "1 !important",
        },
        "&:hover p": {
          opacity: "1 !important",
        },
        "&:hover span": {
          opacity: "1 !important",
        },
      }}
    >
      <Box
        sx={{
          width: "100%", //
          aspectRatio: 1,
          maxWidth: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          mb: theme.spacing(2),
        }}
      >
        <img src={Hexagon} width={"100%"} alt="pros" style={{ opacity: 0.8 }} />

        <Box sx={{ position: "absolute", fontSize: "38px", display: "flex", justifyContent: "center", alignItems: "center", color: theme.palette.text.primary, opacity: 0.8 }}>
          {icon}
        </Box>
      </Box>

      <Typography component={"p"} variant="body1" textAlign={"center"}>
        {header}
      </Typography>
      <Typography component={"p"} variant="subtitle1" textAlign={"center"} sx={{ fontWeight: 200, opacity: 0.6 }}>
        {text}
      </Typography>
    </BorderBox>
  );
};
