import React from "react";

import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type DotProps = {
  stage: "empty" | "semi" | "full";
};

const Dot: React.FC<DotProps> = ({ stage }) => {
  const theme = useTheme();

  let background = "";
  switch (stage) {
    default:
    case "empty":
      background = `${theme.palette.background.paper}`;
      break;
    case "semi":
      background = `linear-gradient(90deg, ${theme.palette.secondary.main} 50%, ${theme.palette.background.paper} 50%)`;
      break;
    case "full":
      background = `${theme.palette.secondary.main}`;
      break;
  }

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Box
        component={"span"}
        sx={{
          width: "15px",
          height: "15px",
          borderRadius: "100px",
          background,
          border: `2px solid ${theme.palette.secondary.main}`,
        }}
      ></Box>
    </Box>
  );
};

type ProjectsArrowProps = {
  isHorizontal: boolean;
};

export const ProjectsArrow: React.FC<ProjectsArrowProps> = ({ isHorizontal }) => {
  const theme = useTheme();

  return (
    <Stack direction={isHorizontal ? "row" : "column"} sx={{ width: isHorizontal ? "100%" : "auto" }} justifyContent={"center"} alignItems={"center"} position={"relative"}>
      <Box
        sx={{
          flexGrow: 1,
          width: isHorizontal ? "100%" : "2px",
          height: isHorizontal ? "2px" : "100%",
          borderTopLeftRadius: isHorizontal ? "50%" : undefined,
          borderBottomLeftRadius: isHorizontal ? "50%" : undefined,
          background: `linear-gradient(${isHorizontal ? "90deg" : "180deg"}, transparent -10%, ${theme.palette.secondary.main} 100%)`,
        }}
      ></Box>
      <Box
        component={"span"} //
        sx={{
          width: "0px", //
          height: "0px",
          borderTop: "15px solid transparent",
          borderBottom: "15px solid transparent",
          borderLeft: `15px solid ${theme.palette.secondary.main}`,
          transform: isHorizontal ? "scaleY(0.5) " : "scaleX(0.5) rotate(90deg) translateX(-50%)",
        }}
      ></Box>
      <Stack direction={isHorizontal ? "row" : "column"} justifyContent={"space-evenly"} alignItems={"center"} sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}>
        <Dot stage="full" />
        <Dot stage="semi" />
        <Dot stage="empty" />
        <Dot stage="empty" />
      </Stack>
    </Stack>
  );
};
