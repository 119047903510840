import React, { useRef, useState } from "react";
import { Box, Container, Stack, Typography, MenuItem, Button, Paper, MenuList, Popper, Grow, useMediaQuery, Alert } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTheme, alpha } from "@mui/material/styles";
import { FaGhost } from "react-icons/fa";
import { ReactComponent as ProjectLogo } from "../../assets/icons/project-logo.svg";
import { ReactComponent as MainnetIcon } from "../../assets/icons/mainnet.svg";

import "./Navbar.sass";

import { useApp } from "../../hooks/useApp";
import { Network } from "../../types";
import { InfoOutlined } from "@mui/icons-material";

export const Navbar = () => {
  const app = useApp();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (selectedNetwork: Network) => {
    setTimeout(() => {
      app.selectNetwork(selectedNetwork);
    }, 200);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box
      sx={{
        position: "fixed",
        bgcolor: theme.palette.background.default,
        top: 0,
        width: "100%",
        py: isMdDown ? 1 : 2,
        zIndex: 9000,
        borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.25)}`,
      }}
    >
      {showNotification && (
        <Alert
          severity="info"
          icon={false}
          onClose={() => {
            setShowNotification(false);
          }}
          variant="filled"
          sx={{
            color: "#eee",
            marginBottom: isMdDown ? 1 : 2,
            mt: isMdDown ? -1 : -2,
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
          }}
          classes={{
            message: "alertMessage",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <InfoOutlined />
            <Typography fontSize={"0.8rem"}>Casper 2.0 is on the horizon, and we're commited to support it — if the grant backs us up 💸</Typography>
          </Stack>
        </Alert>
      )}
      <Container maxWidth={"md"}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
          <Stack flexDirection={"row"} justifyContent={"start"} alignItems={"center"} gap={1}>
            <ProjectLogo style={{ fontSize: "30px" }} />
            <Typography variant={"h3"} component={"span"} sx={{ fontWeight: 700 }}>
              Phantom RPC
            </Typography>
          </Stack>

          <div>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              disableRipple
              sx={{ background: theme.palette.background.paper, color: theme.palette.text.primary, border: `1px solid ${alpha(theme.palette.primary.main, 0.25)}` }}
              aria-label="Network"
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <MainnetIcon fill={app.network === "mainnet" ? "red" : "#00bba4"} />
                <Typography sx={{ fontSize: "0.8rem" }}>{app.network.toUpperCase()}</Typography>
              </Stack>
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper sx={{ background: theme.palette.background.paper }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown} sx={{ mt: 0.5 }}>
                        {app.network !== "mainnet" && (
                          <MenuItem onClick={() => handleChange("mainnet")}>
                            <Stack direction={"row"} alignItems={"center"} gap={1}>
                              <MainnetIcon fill="red" />
                              <Typography sx={{ fontSize: "0.8rem" }}>MAINNET</Typography>
                            </Stack>
                          </MenuItem>
                        )}
                        {app.network !== "testnet" && (
                          <MenuItem onClick={() => handleChange("testnet")}>
                            <Stack direction={"row"} alignItems={"center"} gap={1}>
                              <MainnetIcon fill="#00bba4" />
                              <Typography sx={{ fontSize: "0.8rem" }}>TESTNET</Typography>
                            </Stack>
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      </Container>
    </Box>
  );
};
