import React from "react";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Tabs, Tab, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CurlSnippet } from "./JsonRpcTabsCurlSnippet";
import { GoSnippet } from "./JsonRpcTabsGoSnippet";
import { RustSnippet } from "./JsonRpcTabsRustSnippet";
import { PythonSnippet } from "./JsonRpcTabsPythonSnippet";
import { JavaScriptSnippet } from "./JsonRpcTabsJavaScriptSnippet";

type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
};

type JsonRpcTabsProps = {};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const JsonRpcTabs: React.FC<JsonRpcTabsProps> = () => {
  const theme = useTheme();

  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "transparent", width: "100%" }}>
      <Typography sx={{pb: 2}}>
        Below are examples of how to fetch the <strong>latest state root hash</strong> from the Casper network using different programming languages.
      </Typography>
      <AppBar position="static" sx={{ bgcolor: "transparent", backgroundImage: "none", boxShadow: "none", borderRadius: "5px", overflow: "hidden" }}>
        <Tabs
          value={value} //
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="code examples tabs"
          scrollButtons
          allowScrollButtonsMobile
          sx={{ bgcolor: "rgba(255, 255, 255, 0.05)" }}
        >
          <Tab label="curl" {...a11yProps(0)} />
          <Tab label="JavaScript" {...a11yProps(1)} />
          <Tab label="Python" {...a11yProps(2)} />
          <Tab label="Rust" {...a11yProps(3)} />
          <Tab label="Go" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CurlSnippet />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <JavaScriptSnippet />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <PythonSnippet />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <RustSnippet />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <GoSnippet />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
