import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { Typography } from "@mui/material";
import { SxProps, styled, useTheme } from "@mui/material/styles";

import "./SingleSummaryInfo.css";

import { FaNetworkWired } from "react-icons/fa";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "transparent",
  position: "relative",
  width: "100%",
  padding: theme.spacing(0),
}));

type SingleSummaryInfoProps = {
  header: string;
  value: string | number;
  subheader?: string;
  hint?: string;
  color?: "primary" | "secondary";
  wordsToColorize?: string[];
  caseSensitive?: boolean;
  numberDivider?: "," | true;
  useSeparator?: boolean;
};

export const SingleSummaryInfo: React.FC<SingleSummaryInfoProps & BoxProps> = ({
  header,
  value,
  subheader,
  hint,
  color,
  wordsToColorize,
  caseSensitive,
  numberDivider,
  useSeparator,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  let textColor: string | undefined;

  switch (color) {
    case "primary":
      textColor = theme.palette.primary.main;
      break;
    case "secondary":
      textColor = theme.palette.secondary.main;
      break;
    default:
      textColor = undefined;
      break;
  }

  const styles: SxProps = {};

  if (useSeparator) {
    const splitedValue = value.toString().split(".");
    const vauleToSeparate = splitedValue.shift();
    const reversed = vauleToSeparate?.split("").reverse();
    const separated = reversed
      ?.map((char, index) => {
        if ((index + 1) % 3 === 0) {
          return " " + char;
        } else return char;
      })
      .reverse()
      .join("") as string;
    splitedValue.unshift(separated);
    value = splitedValue.join("");
  }

  return (
    <StyledBox sx={{ ...styles, ...sx }} {...rest}>
      <Typography component={"p"} variant={"h5"} sx={{ mb: "0.1rem" }}>
        {colorizeText(header, wordsToColorize, caseSensitive, textColor)}
      </Typography>
      <Typography component={"p"} variant={"subtitle2"} sx={{ opacity: 0.85, fontWeight: 200, mb: "0.3rem" }}>
        {subheader}
      </Typography>
      <Typography component={"p"} variant={"h2"} noWrap>
        {value}
      </Typography>
      <Typography component={"p"} variant={"caption"} sx={{ opacity: 0.7, fontWeight: 200 }}>
        {hint}
      </Typography>

      {/* <FaNetworkWired style={{ fill: textColor, position: "absolute", bottom: "-0.5rem", right: "-0.5rem" }} className="single__summary__info__blink" /> */}
    </StyledBox>
  );
};

function colorizeText(text: string, wordsToColorize: string[] = [], caseSensitive: boolean = false, textColor?: string) {
  const words = text.split(" ");

  const reactElArr: React.DetailedReactHTMLElement<
    {
      className: string;
    },
    HTMLElement
  >[] = [];

  words.forEach((word, index) => {
    const match = wordsToColorize.includes(word.trim()) || (!caseSensitive && wordsToColorize.map((el) => el.toLowerCase()).includes(word.toLowerCase().trim()));

    if (!match) {
      const rel = React.createElement("span", { key: index, className: "" }, word);
      reactElArr.push(rel);
    } else {
      const rel = React.createElement("span", { key: index, className: "", style: { color: textColor } }, word);
      reactElArr.push(rel);
    }

    if (index < words.length - 1) {
      const rel = React.createElement("span", { key: words.length, className: "" }, " ");
      reactElArr.push(rel);
    }
  });

  const reactEl = React.createElement("span", { className: "" }, [...reactElArr]);

  return reactEl;
}
