import React, { useEffect, useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";

import { useApp } from "../../hooks/useApp";
import { Button } from "../button/Button";

// Random non-existing state root hash example.
const DEFAULT_STATE_ROOT_HASH = "4989bcd4c1d94813686dbba82af34f2c2e8fe91e53284f9efada836290844133";

export const DemoServerRequest = () => {
  const theme = useTheme();
  const app = useApp();

  const [isSending, setIsSending] = useState<boolean>(false);
  const [lastStateRootHash, setLastStateRootHash] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const handlePostRequest = async () => {
    if (isSending) return;

    setIsSending(true);
    setError(null);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      jsonrpc: "2.0",
      method: "chain_get_state_root_hash",
      id: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    setTimeout(() => {
      fetch(`https://${app.network}.phantom-rpc.com/rpc`, requestOptions)
        .then((response) => {
          if (!response.ok) throw new Error("Something went wrong!");
          return response.json();
        })
        .then((result) => {
          let state_root_hash = result.result?.state_root_hash;

          setLastStateRootHash(state_root_hash);
        })
        .catch((error) => {
          console.log("error", error);
          setError(error);
        })
        .finally(() => {
          setIsSending(false);
        });
    }, 700);
  };

  useEffect(() => {
    setIsSending(false);
    setLastStateRootHash(null);
    setError(null);
  }, [app.network]);

  // Fetch hash during first render - currently disabled.
  useEffect(() => {
    //handlePostRequest();
  }, []);

  return (
    <>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
        <Button size="small" onClick={handlePostRequest} sx={{ px: "1.5rem", height: "45px", whiteSpace: "nowrap", width: "140px" }} flat={true} aria-label="FETCH LATEST">
          {!isSending && "FETCH LATEST"}
          {isSending && <ThreeDots visible={true} height="30" width="30" color="#fff" radius="9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass="" />}
        </Button>
        <Box sx={{ overflow: "hidden", width: "100%", flexGrow: 1 }}>
          <div className="cl-main page__cl-main" style={{ height: "45px" }}>
            <div className="cl-card cl-main__cl-card" style={{ height: "43px", borderColor: "#282c34", borderRadius: "0.2rem", backgroundColor: "#282c34" }}>
              <Box
                sx={{
                  width: "100%", //
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "0 !important",
                  m: 0,
                  p: 0,
                  px: 1,
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: { md: "16px", xs: "14px" }, //
                    fontWeight: 300,
                    m: 0,
                    p: 0,
                    opacity: isSending ? 0.5 : 1,
                  }}
                >
                  {lastStateRootHash || DEFAULT_STATE_ROOT_HASH}
                </Typography>
              </Box>
            </div>
          </div>
        </Box>
      </Stack>
    </>

    // <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"} alignContent={"stretch"} sx={{ width: "100%" }} gap={1}>
    //   <Button color={"secondary"} size={"small"} onClick={handlePostRequest} disabled={isSending} flat>
    //     <span style={{ whiteSpace: "nowrap" }}>FETCH LATEST</span>
    //   </Button>
    //   <Box sx={{ flexGrow: 1, backgroundColor: "#282c34", borderRadius: "0.25rem", py: "0.55rem", px: "0.6rem" }}>
    //     <Typography noWrap>{data?.state_root_hash}</Typography>
    //   </Box>
    // </Stack>
  );
};
