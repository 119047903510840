/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { alpha, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { wsManager } from "../../ws/WebSocketManager";

interface Data {
  id: number;
  chain: string;
  command: string;
  request_location: string;
  time: string;
  timestamp: number; // Store the exact time of the data's arrival
}

function createData(id: number, chain: string, command: string, request_location: string, time: string, timestamp: number): Data {
  return {
    id,
    chain,
    command,
    request_location,
    time,
    timestamp,
  };
}

const initialRows = [
  createData(-1, "-", "-", "-", "-", 0),
  createData(-2, "-", "-", "-", "-", 0),
  createData(-3, "-", "-", "-", "-", 0),
  createData(-4, "-", "-", "-", "-", 0),
  createData(-5, "-", "-", "-", "-", 0),
];

function getTimeAgo(timestamp: number) {
  if (timestamp === 0) return "-";

  const now = Date.now();
  const secondsAgo = Math.round((now - timestamp) / 1000);

  if (secondsAgo === 0) return `Just now`;
  if (secondsAgo < 60) return `${secondsAgo}s ago`;
  const minutesAgo = Math.round(secondsAgo / 60);
  if (minutesAgo < 60) return `${minutesAgo}m ago`;
  const hoursAgo = Math.round(minutesAgo / 60);
  if (hoursAgo < 24) return `${hoursAgo}h ago`;
  const daysAgo = Math.round(hoursAgo / 24);
  return `${daysAgo}d ago`;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "chain",
    numeric: false,
    disablePadding: true,
    label: "Network",
  },
  {
    id: "command",
    numeric: false,
    disablePadding: true,
    label: "Command",
  },
  {
    id: "request_location",
    numeric: false,
    disablePadding: true,
    label: "Location",
  },
  {
    id: "time",
    numeric: false,
    disablePadding: true,
    label: "Time",
  },
];

interface NodesInActionProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function NodesInActionHead(props: NodesInActionProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ borderBottom: "1px solid rgba(255, 255, 255, 0.05)" }}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ px: theme.spacing(0.5), pl: 0 }}
            > */}
            <Typography color="primary" variant="body2" sx={{ pb: theme.spacing(1) }}>
              {headCell.label}
            </Typography>
            {/* 
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface NodesInActionToolbarProps {
  numSelected: number;
}

function NodesInActionToolbar(props: NodesInActionToolbarProps) {
  const { numSelected } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        p: "0px !important",
        minHeight: "auto",
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography component={"p"} variant={"h4"} sx={{ mb: theme.spacing(2) }}>
          Nodes{" "}
          <Typography component={"span"} variant={"h4"} color={"primary"}>
            in action
          </Typography>
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export const RequestsLogger = () => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("timestamp");
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Data[]>(initialRows);
  const [logId, setLogId] = useState<number>(0);

  const theme = useTheme();

  const getOpacity = (location: string) => {
    const isHealthCheck = location?.toLowerCase().includes("health");

    if (isHealthCheck) return 0.5;

    return 1;
  };

  const getColor = (location: string) => {
    const isHealthCheck = location?.toLowerCase().includes("health");

    if (isHealthCheck) return theme.palette.success.main;

    return theme.palette.text.primary;
  };

  // Update timers.
  useEffect(() => {
    const timerID = setInterval(() => {
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          time: getTimeAgo(row.timestamp),
        }))
      );
    }, 1000); // Update every second

    return () => {
      clearInterval(timerID); // Clean up on component unmount
    };
  }, []);

  // Live traffic from both chains.
  useEffect(() => {
    wsManager.connect();

    const unsubscribe = wsManager.subscribe((data) => {
      // Data format:
      // {
      //   chain: "testnet",
      //   command: "state_get_dictionary_item",
      //   location:
      //   {
      //     hexagonCountry: "Germany",
      //     hexagonId: 2001
      //   }
      // }
      let hexagonId = data["location"]["hexagonId"];
      if (data["command"].startsWith("err_")) {
        // console.warn("Skipping error command.");
        return;
      }

      setLogId((prevId) => {
        const newId = prevId + 1;
        const newData = createData(
          newId, // Use the incremented ID
          data.chain,
          data.command,
          data.location.hexagonCountry,
          "Just now",
          Date.now()
        );

        setRows((prevRows) => {
          const updatedRows = [newData, ...prevRows];
          return updatedRows.slice(0, 5); // Keep only the first 5 entries
        });

        return newId;
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        square
        sx={{
          width: "100%", //
          mb: 0,
          backgroundColor: "transparent",
          //   backgroundColor: "hsla(0, 0%, 0%, 0.3)",
          //   backgroundImage: "none",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.0))",
          boxShadow: "none",
          p: theme.spacing(2),
        }}
      >
        {/* <NodesInActionToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"} style={{ tableLayout: "fixed" }}>
            <NodesInActionHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                    // sx={{ cursor: "pointer" }}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell> */}
                    <TableCell
                      align="left" //
                      padding="none"
                      sx={{ px: theme.spacing(0.5), py: theme.spacing(1), pl: 0, border: "none" }}
                    >
                      <Typography variant="subtitle2" noWrap sx={{ opacity: getOpacity(row.request_location) }}>
                        {row.chain}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th" //
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ px: theme.spacing(0.5), py: theme.spacing(1), pl: 0, border: "none" }}
                    >
                      <Typography variant="subtitle2" noWrap sx={{ opacity: getOpacity(row.request_location) }}>
                        {row.command}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{ px: theme.spacing(0.5), py: theme.spacing(1), pl: 0, border: "none" }}>
                      <Typography variant="subtitle2" noWrap sx={{ opacity: getOpacity(row.request_location), color: getColor(row.request_location) }}>
                        {row.request_location}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" padding="none" sx={{ px: theme.spacing(0.5), py: theme.spacing(1), pl: 0, border: "none" }}>
                      <Typography variant="subtitle2" noWrap sx={{ opacity: getOpacity(row.request_location) }}>
                        {row.time}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> */}
    </Box>
  );
};
