export function capitalizeString(str: string) {
  if (!str) return "";

  str = str.toString();

  const charArr = str.split("");
  const firstCharCapitalized = charArr.shift()!.toUpperCase();

  charArr.unshift(firstCharCapitalized);

  const result = charArr.join("");

  return result;
}
