import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";

export const Footer: React.FC = () => {
  return (
    <Box sx={{ borderBottom: "0.5rem solid white" }}>
      <Container maxWidth="md">
        <Stack direction="row" justifyContent="end" alignItems="center" sx={{ width: "100%" }} gap={"0.5rem"}>
          <Typography variant="subtitle2" sx={{ pt: "0.25rem" }}>
            Developed by
          </Typography>
          <Box component={"a"} href="https://reddotsoftware.dev" target="_blank" sx={{ bgcolor: "white", padding: "0.5rem", paddingBottom: 0, mr: "10%" }}>
            <img src="./logo-reddot.png" alt="Reddot logo" style={{ width: "auto", height: "30px" }} />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
