import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";

import { SSEChannel } from "../../types";
import { useApp } from "../../hooks/useApp";

type SSEChannelSelectorProps = {};

export const SSEChannelSelector: React.FC<SSEChannelSelectorProps> = () => {
  const app = useApp();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value as SSEChannel;
    app.selectSSEChannel(newValue);
  };

  return (
    <FormControl>
      {/* <FormLabel id="channel-select-label">Channel</FormLabel> */}
      <RadioGroup
        row //
        aria-labelledby="channel-select-label"
        name="channel-select"
        value={app.sseChannel}
        onChange={handleChange}
      >
        <FormControlLabel value="main" control={<Radio />} label="Main" />
        <FormControlLabel value="deploys" control={<Radio />} label="Deploys" />
        <FormControlLabel value="sigs" control={<Radio />} label="Sigs" />
      </RadioGroup>
    </FormControl>
  );
};
