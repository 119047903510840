import React from "react";

import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ProjectLogo } from "./ProjectLogo";
import { ProjectsArrow } from "./ProjectsArrow";
import { ProjectInfo } from "./ProjectInfo";

import { PiPlugsConnected } from "react-icons/pi";
import { PiDiceSix } from "react-icons/pi";
import { LiaNetworkWiredSolid } from "react-icons/lia";
import { IoMdCodeWorking } from "react-icons/io";

type UpcomingProjectsProps = {};

export const UpcomingProjects: React.FC<UpcomingProjectsProps> = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isSmall ? (
        <Stack direction={"row"} sx={{ width: "100%" }} gap={1}>
          <ProjectsArrow isHorizontal={!isSmall} />

          <Stack direction={"column"} sx={{ width: "100%" }} gap={2}>
            <Box sx={{ mb: theme.spacing(5), display: "flex", flexDirection: "column", gap: 1 }}>
              <ProjectLogo
                icon={<PiPlugsConnected fill={theme.palette.secondary.main} />} //
                name={"Public RPC"}
                status={"Available now"}
              />
              <ProjectInfo header="Seamless blockchain connection" description="The first public RPC infrastructure for dApp connectivity on the Casper Network, ensuring fast and easy access." />
            </Box>
            <Box sx={{ mb: theme.spacing(5), display: "flex", flexDirection: "column", gap: 1 }}>
              <ProjectLogo
                icon={<PiDiceSix fill={theme.palette.secondary.main} />} //
                name={"VFR (Oracle)"}
                status={"Coming soon"}
              />
              <ProjectInfo header="Trusted randomness generation" description="Verifiable Random Function will allow building dApps based on secure and verifiable randomness on the Casper Network." />
            </Box>
            <Box sx={{ mb: theme.spacing(5), display: "flex", flexDirection: "column", gap: 1 }}>
              <ProjectLogo
                icon={<LiaNetworkWiredSolid fill={theme.palette.secondary.main} />} //
                name={"Ephemeral Devnets"}
                status={"Coming in 2024/2025"}
              />
              <ProjectInfo header="Personalized testing environment" description="Launch emulated, short-lived testnets with customizable chainspecs, unlimited CSPR, and short block times, ensuring an isolated and flexible development experience." />
            </Box>
            <Box sx={{ mb: theme.spacing(5), display: "flex", flexDirection: "column", gap: 1 }}>
              <ProjectLogo
                icon={<IoMdCodeWorking fill={theme.palette.secondary.main} />} //
                name={"JS Framework"}
                status={"In consideration"}
              />
              <ProjectInfo header="Simplified smart contract development" description="A JavaScript framework to streamline and simplify smart contract development on the Casper Network." />
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Stack direction={"column"} sx={{ width: "100%" }} gap={1}>
          <Stack direction={"row"} sx={{ width: "100%" }} gap={2}>
            <ProjectLogo icon={<PiPlugsConnected fill={theme.palette.secondary.main} />} name={"Public RPC"} status={"Available now"} />
            <ProjectLogo icon={<PiDiceSix fill={theme.palette.secondary.main} />} name={"VFR (Oracle)"} status={"Coming soon"} />
            <ProjectLogo icon={<LiaNetworkWiredSolid fill={theme.palette.secondary.main} />} name={"Ephemeral Devnets"} status={"Coming in 2024/2025"} />
            <ProjectLogo icon={<IoMdCodeWorking fill={theme.palette.secondary.main} />} name={"JS Framework"} status={"In consideration"} />
          </Stack>

          <ProjectsArrow isHorizontal={!isSmall} />

          <Stack direction={"row"} sx={{ width: "100%" }} gap={2}>
            <ProjectInfo header="Seamless blockchain connection" description="The first public RPC infrastructure for dApp connectivity on the Casper Network, ensuring fast and easy access." />
            <ProjectInfo header="Trusted randomness generation" description="Verifiable Random Function will allow building dApps based on secure and verifiable randomness on the Casper Network." />
            <ProjectInfo header="Personalized testing environment" description="Launch emulated, short-lived testnets with customizable chainspecs, unlimited CSPR, and short block times, ensuring an isolated and flexible development experience." />
            <ProjectInfo header="Simplified smart contract development" description="A JavaScript framework to streamline and simplify smart contract development on the Casper Network." />
          </Stack>
        </Stack>
      )}
    </>
  );
};
