import React, { useEffect, useRef, MutableRefObject } from "react";

import * as d3 from "d3";

import { getPrecomputedValues } from "./precomputed";
import { wsManager } from "../../ws/WebSocketManager";

// Fix based on https://github.com/d3/d3-transition/issues/93#issuecomment-516452828.
d3.selection.prototype.maybeTransition = function (milliseconds: number, name?: string) {
  if (document.visibilityState === "visible") {
    return this.transition(name).duration(milliseconds);
  } else {
    return this;
  }
};
d3.transition.prototype.maybeTransition = function (milliseconds: number, name?: string) {
  if (document.visibilityState === "visible") {
    return this.transition(name).duration(milliseconds);
  } else {
    return this;
  }
};

const { mapSize, targetProjection, hexbinGridCenters, hexbinGridRadius } = getPrecomputedValues();

interface LiveMapHexbinProps {
  circleFinalColor?: string;
  circleFinalOpacity?: number;
  circlePulseFill?: string;
  circlePulseInTime?: number; // Miliseconds for growing circle.
  circlePulseOpacity?: number;
  circlePulseOutTime?: number; // Miliseconds for reducing circle size.
  circlePulseSizeRelative?: number;
  circleSizeRelative?: number; // Circle radius, ratio relative to hexagon radius - value between 0 and 1.
  sphereFillEnabled: boolean;
  sphereFillColor?: string;
  sphereStrokeEnabled: boolean;
  sphereStrokeColor?: string;
}

export function LiveMapHexbin({
  circleFinalColor = "#ffffff",
  circleFinalOpacity = 0.2,
  circlePulseFill = "#00bba4",
  circlePulseInTime = 3500,
  circlePulseOpacity = 1,
  circlePulseOutTime = 500,
  circlePulseSizeRelative = 1.42,
  circleSizeRelative = 0.7,
  sphereFillEnabled = true,
  sphereFillColor = "#2b303b",
  sphereStrokeEnabled = true,
  sphereStrokeColor = "#135f79",
}: // 45d998
LiveMapHexbinProps) {
  const d3ContainerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  // Compute radius for circles (intial and after pulse).
  const circleRadius = Math.round(hexbinGridRadius * 100 * circleSizeRelative) / 100;
  const circlePulseRadius = Math.round(hexbinGridRadius * 100 * circlePulseSizeRelative) / 100;

  // Draw map.
  useEffect(() => {
    if (d3ContainerRef.current === null) {
      return;
    }
    const d3ContainerRefCopy = d3ContainerRef.current; // Used for safe cleanup.

    // Base SVG container.
    const svg = d3.select(d3ContainerRef.current).append("svg").attr("viewBox", `0 0 ${mapSize.width} ${mapSize.height}`).attr("width", "100%").attr("height", "100%");

    // Optional rectangular background.
    /*svg
      .append("rect")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("fill", "grey");*/

    // Sphere background - bottom layer.
    if (sphereFillEnabled === true) {
      svg
        .append("path")
        .datum({ type: "Sphere" })
        // @ts-ignore
        .attr("d", d3.geoPath().projection(targetProjection))
        .attr("fill", sphereFillColor);
    }

    // Hexbin grid - center layer.
    svg
      .append("g")
      .selectAll("circle")
      .data(hexbinGridCenters)
      .join("circle")
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .attr("r", circleRadius)
      .attr("fill", circleFinalColor)
      .attr("id", (d) => `circle-${d.id}`)
      .attr("opacity", circleFinalOpacity);

    // Sphere border - top layer.
    if (sphereStrokeEnabled === true) {
      svg
        .append("path")
        .datum({ type: "Sphere" })
        // @ts-ignore
        .attr("d", d3.geoPath().projection(targetProjection))
        .attr("stroke", sphereStrokeColor)
        .attr("fill", "transparent");
    }

    return () => {
      d3.select(d3ContainerRefCopy).selectAll("svg").remove();
    };
  }, [circleFinalColor, circleFinalOpacity, circleRadius, circleSizeRelative, sphereFillColor, sphereFillEnabled, sphereStrokeColor, sphereStrokeEnabled]);

  // Live traffic from both chains.
  useEffect(() => {
    wsManager.connect();

    const unsubscribe = wsManager.subscribe((data) => {
      // Data format:
      // {
      //   chain: "testnet",
      //   command: "state_get_dictionary_item",
      //   location:
      //   {
      //     hexagonCountry: "Germany",
      //     hexagonId: 2001
      //   }
      // }
      let hexagonId = data["location"]["hexagonId"];
      if (data["command"].startsWith("err_")) {
        // console.warn("Skipping error command.");
        return;
      }

      const circle = d3.select(`#circle-${hexagonId}`);
      circle
        //.raise()
        .maybeTransition(circlePulseOutTime)
        .attr("r", circlePulseRadius)
        .attr("opacity", circlePulseOpacity)
        .attr("fill", circlePulseFill)
        .maybeTransition(circlePulseInTime)
        .attr("opacity", circleFinalOpacity)
        .attr("r", circleRadius)
        .attr("fill", circleFinalColor);
    });

    return () => {
      unsubscribe();
    };
  }, [circleFinalColor, circleFinalOpacity, circlePulseFill, circlePulseInTime, circlePulseOpacity, circlePulseOutTime, circlePulseRadius, circleRadius]);

  return <div ref={d3ContainerRef} />;
}
