import React, { useState, useCallback } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";

import { Button } from "../button/Button";
import { useApp } from "../../hooks/useApp";

import "./CopyLink.sass";
import { SSEChannelSelector } from "./SSEChannelSelector";

type CopyLinkProps = {
  value: string;
};

export const CopyLink: React.FC<CopyLinkProps> = ({ value }) => {
  const app = useApp();
  const theme = useTheme();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(async () => {
    if (copied) return;

    try {
      await navigator.clipboard.writeText(value || "No content");
      setCopied(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [value, copied]);

  return (
    <>
      {app.endpointType === "events-sse" && <SSEChannelSelector />}
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
        <Box sx={{ overflow: "visible", width: "100%" }}>
          <div className="cl-main page__cl-main">
            <div className="cl-card cl-main__cl-card">
              <Box
                sx={{
                  width: "100%", //
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 0,
                  p: 0,
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: { md: "20px", xs: "14px" }, //
                    fontWeight: 500,
                    m: 0,
                    px: 1.5,
                  }}
                >
                  {value}
                </Typography>
              </Box>
              <div aria-hidden="true">
                <span className="cl-card__line cl-card__line_left" />
                <span className="cl-card__line cl-card__line_right" />
                <span className="cl-card__line cl-card__line_top" />
                <span className="cl-card__line cl-card__line_bottom" />
              </div>
            </div>
          </div>
        </Box>
        <Button
          size="large"
          onClick={handleCopy}
          flat={true}
          color={copied ? "secondary" : "primary"}
          disableRipple
          sx={{ transition: "none", "&:hover": { background: !copied ? `${theme.palette.primary.dark} !important` : undefined } }}
          aria-label="Copy"
        >
          {!copied && <ContentCopyIcon />}
          {copied && <DoneIcon />}
        </Button>
      </Stack>
    </>
  );
};
