import React from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type ProjectInfoProps = {
  header: string;
  description: string;
};

export const ProjectInfo: React.FC<ProjectInfoProps> = ({ header, description }) => {
  const theme = useTheme();

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
      <Typography variant="h6" textAlign={"center"} sx={{ color: theme.palette.text.primary, fontWeight: 400, mb: theme.spacing(0.5), textWrap: "balance" }}>
        {header}
      </Typography>
      <Typography variant="body2" textAlign={"center"} sx={{ color: theme.palette.text.secondary, fontWeight: 300, textWrap: "balance" }}>
        {description}
      </Typography>
    </Box>
  );
};
