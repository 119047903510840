import { Lang } from "../types";

export const languages: Lang[] = [
  {
    name: "English",
    langCode: "en",
    flagCode: "gb",
  },
  {
    name: "Polish",
    langCode: "pl",
    flagCode: "pl",
  },
];
