import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { useTranslation } from "react-i18next";

import { AppTheme, Lang, Network, EndpointType, SSEChannel } from "../../types";
import { initializeTranslation } from "../../config/i18n";
import { languages } from "../../config/constants";
import { LoadingScreen } from "../../components/screens/loading-screen/LoadingScreen";

type AppContextProviderProps = {
  themeMode: AppTheme;
  setThemeMode: React.Dispatch<React.SetStateAction<AppTheme>>;
  children: React.ReactNode;
};

type AppState = {
  isLoading: boolean;
  theme: AppTheme;
  network: Network;
  sseChannel: SSEChannel;
  endpointType: EndpointType;
  language: Lang;
};

interface AppContextData extends AppState {
  selectTheme: (themeMode: AppTheme) => void;
  selectNetwork: (networkType: Network) => void;
  selectSSEChannel: (channel: SSEChannel) => void;
  selectEndpointType: (endpointType: EndpointType) => void;
  selectLanguage: (lang: Lang) => void;
  getEndpointValue: () => string;
}

const initialState: AppState = {
  isLoading: true,
  theme: "dark",
  network: "mainnet",
  sseChannel: "main",
  endpointType: "json-rpc",
  language: languages[0],
};

initializeTranslation(initialState.language.langCode);

export const AppContext = createContext<AppContextData>({
  ...initialState,
  selectTheme: () => null,
  selectNetwork: () => null,
  selectSSEChannel: () => null,
  selectEndpointType: () => null,
  selectLanguage: () => null,
  getEndpointValue: () => "",
});

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, themeMode, setThemeMode }) => {
  const [state, setState] = useState<AppState>(initialState);
  const [counter, setCounter] = useState<number>(0);
  const { i18n } = useTranslation();

  // Fake loading...
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 100) {
        setCounter((prev) => (prev += Math.ceil(((100 - prev) * 41) / 100)));
      } else {
        setTimeout(() => {
          setState((prev) => ({ ...prev, isLoading: false }));
        }, 200);
        clearInterval(intervalId);
      }
    }, 25);

    return () => clearInterval(intervalId);
  }, [counter]);

  const selectTheme = (themeMode: AppTheme) => {
    setThemeMode(themeMode);
  };

  const selectNetwork = (networkType: Network) => {
    setState((prev) => ({ ...prev, network: networkType }));
  };

  const selectSSEChannel = (channel: SSEChannel) => {
    setState((prev) => ({ ...prev, sseChannel: channel }));
  };

  const selectEndpointType = (endpointType: EndpointType) => {
    setState((prev) => ({ ...prev, endpointType }));
  };

  const selectLanguage = (lang: Lang) => {
    i18n.changeLanguage(lang.langCode);
    setState((prev) => ({ ...prev, language: lang }));
  };

  const getEndpointValue = () => {
    let endpoint = "";

    if (state.network === "mainnet") {
      if (state.endpointType === "json-rpc") endpoint = "https://mainnet.phantom-rpc.com/rpc";
      if (state.endpointType === "events-sse" && state.sseChannel === "main") endpoint = "https://mainnet.phantom-rpc.com/events/main";
      if (state.endpointType === "events-sse" && state.sseChannel === "deploys") endpoint = "https://mainnet.phantom-rpc.com/events/deploys";
      if (state.endpointType === "events-sse" && state.sseChannel === "sigs") endpoint = "https://mainnet.phantom-rpc.com/events/sigs";
      if (state.endpointType === "speculative-exec") endpoint = "Coming soon...";
    }

    if (state.network === "testnet") {
      if (state.endpointType === "json-rpc") endpoint = "https://testnet.phantom-rpc.com/rpc";
      if (state.endpointType === "events-sse" && state.sseChannel === "main") endpoint = "https://testnet.phantom-rpc.com/events/main";
      if (state.endpointType === "events-sse" && state.sseChannel === "deploys") endpoint = "https://testnet.phantom-rpc.com/events/deploys";
      if (state.endpointType === "events-sse" && state.sseChannel === "sigs") endpoint = "https://testnet.phantom-rpc.com/events/sigs";
      if (state.endpointType === "speculative-exec") endpoint = "Coming soon...";
    }

    return endpoint;
  };

  return (
    <AppContext.Provider
      value={{
        ...{ ...state, theme: themeMode },
        selectTheme,
        selectNetwork,
        selectSSEChannel,
        selectEndpointType,
        selectLanguage,
        getEndpointValue,
      }}
    >
      {state.isLoading && <LoadingScreen value={counter} />}
      {!state.isLoading && children}
    </AppContext.Provider>
  );
};
