import React from "react";

import { CodeBlock } from "../code-block/CodeBlock";
import { useApp } from "../../hooks/useApp";

import "prismjs/components/prism-rust";

export const RustSnippet: React.FC = () => {
  const app = useApp();

  const code = `\
use reqwest::Client;
use serde_json::json;

#[tokio::main]
async fn main() {
  let client = Client::new();
  let data = json!({
    "jsonrpc": "2.0",
    "method": "chain_get_state_root_hash",
    "id": 1
  });
  let response = client.post("https://${app.network}.phantom-rpc.com/rpc")
    .json(&data)
    .send()
    .await
    .expect("Failed to send request");
  let response_text = response.text().await.expect("Failed to read response");
  println!("{}", response_text);
}\
`;

  return (
    <CodeBlock
      className="lang-rust"
      code={code}
      preAttrs={{ className: "line-numbers" }}
    />
  );
};
