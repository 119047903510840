import React from "react";

import { Grid, Box, Typography, Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

const PartnerHero = () => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ background: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.0));", borderRadius: "0.5rem", p: 3 }}>
      <Grid container spacing={isUpMd ? 6 : 3} sx={{ mb: isUpMd ? 0 : 2 }}>
        <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ mb: 2 }}>
            <a href="https://ramp.network/" target="_blank" rel="noreferrer">
              <img src={"/ramp_logo.png"} alt="add_logo" style={{ width: "180px", height: "auto" }} />
            </a>
          </Box>
          <Typography component={"p"} textAlign={"center"} sx={{ textWrap: "balance", fontSize: "25px", fontWeight: 500, mb: 2 }}>
            Empower users to{" "}
            <Typography component={"span"} sx={{ color: "#21bf73", textWrap: "balance", fontSize: "inherit" }}>
              buy & sell crypto
            </Typography>{" "}
            inside your app
          </Typography>
          <Stack gap={2} flexDirection={"row"}>
            <Button
              sx={{
                bgcolor: "#21bf73 !important",
                // border: "2px solid #21bf73 !important",
                color: "white !important",
                "&:hover": {
                  bgcolor: "#22774e !important",
                  // border: "2px solid #21bf73 !important",
                  // color: "#21bf73 !important",
                },
                p: 1.5,
              }}
              size="large"
              disableRipple
              role="link"
              onClick={() => openInNewTab("https://ramp.network/buy")}
              aria-label="Integrate Ramp"
            >
              Integrate Ramp
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ width: "100%", position: "relative", mb: isUpMd ? "20px" : "0px", pl: isUpMd ? "0px" : "20px" }}>
            <img src={"/ramp_app.png"} alt="add_1" style={{ width: "100%", height: "auto" }} />
            <Box sx={{ height: "100%", position: "absolute", top: "20px", left: isUpMd ? "-20px" : "0px" }}>
              <img src={"/ramp_mobile.png"} alt="add_2" style={{ width: "auto", height: "100%" }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerHero;
