import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useApp } from "../../hooks/useApp";
import { EndpointType } from "../../types";

export const EndpointTypeSwitch = () => {
  const app = useApp();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (endpointType: EndpointType) => {
    app.selectEndpointType(endpointType);
  };

  return (
    <Box
      sx={{
        width: "100%", //
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        px: theme.spacing(3),
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={app.network}
        exclusive
        aria-label="EndpointType"
        sx={{
          p: "5px",
          bgcolor: theme.palette.background.default,
          borderRadius: "0px",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
          overflow: "hidden",
        }}
      >
        <ToggleButton
          value="json-rpc"
          onClick={(e: any) => handleChange(e.target.value)} //
          sx={{
            background: app.endpointType === "json-rpc" ? `${theme.palette.primary.main} !important` : `${theme.palette.background.default} !important`,
            color: app.endpointType === "json-rpc" ? `${theme.palette.text.primary} !important` : `${theme.palette.text.disabled} !important`,
            border: `0px solid ${theme.palette.text.primary} !important`,
            borderRadius: 0,
            borderTopLeftRadius: "0.3rem",
            textShadow: "1px 1px 5px black",
            px: isSmall ? "0.6rem" : "1rem",
            py: isSmall ? "0.3rem" : "0.6rem",
            fontSize: isSmall ? "0.8rem" : "1rem",
            transition: "all 0.2s ease",
            textTransform: "capitalize",
            "&:hover": {
              color: `${theme.palette.text.primary} !important`,
            },
          }}
        >
          {`${app.network} RPC`}
        </ToggleButton>
        <ToggleButton
          value="events-sse"
          onClick={(e: any) => handleChange(e.target.value)} //
          sx={{
            background: app.endpointType === "events-sse" ? `${theme.palette.primary.main} !important` : `${theme.palette.background.default} !important`,
            color: app.endpointType === "events-sse" ? `${theme.palette.text.primary} !important` : `${theme.palette.text.disabled} !important`,
            border: `0px solid ${theme.palette.text.primary} !important`,
            borderRadius: 0,
            borderTopRightRadius: "0.3rem",
            textShadow: "1px 1px 5px black",
            px: isSmall ? "0.6rem" : "1rem",
            py: isSmall ? "0.3rem" : "0.6rem",
            fontSize: isSmall ? "0.8rem" : "1rem",
            transition: "all 0.2s ease",
            textTransform: "capitalize",
            "&:hover": {
              color: `${theme.palette.text.primary} !important`,
            },
          }}
        >
          {`${app.network} SSE`}
        </ToggleButton>
        {/* <ToggleButton
          value="speculative-exec"
          onClick={(e: any) => handleChange(e.target.value)} //
          sx={{
            background: app.endpointType === "speculative-exec" ? `${theme.palette.primary.main} !important` : `${theme.palette.background.default} !important`,
            color: app.endpointType === "speculative-exec" ? `${theme.palette.text.primary} !important` : `${theme.palette.text.disabled} !important`,
            border: `0px solid ${theme.palette.text.primary} !important`,
            borderRadius: 0,
            borderTopRightRadius: "0.3rem",
            textShadow: "1px 1px 5px black",
            px: isSmall ? "0.6rem" : "1rem",
            py: isSmall ? "0.3rem" : "0.6rem",
            fontSize: isSmall ? "0.8rem" : "1rem",
            transition: "all 0.2s ease",
            "&:hover": {
              color: `${theme.palette.text.primary} !important`,
            },
          }}
        >
          {"Speculative Exec RPC"}
        </ToggleButton> */}
      </ToggleButtonGroup>
    </Box>
  );
};
