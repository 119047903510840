import React from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import HexagonSecondary from "../../assets/images/hexagon-frame-secondary.png";

type ProjectLogoProps = {
  icon: React.ReactNode;
  name: string;
  status?: string;
};

export const ProjectLogo: React.FC<ProjectLogoProps> = ({ icon, name, status }) => {
  const theme = useTheme();

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
      <Box
        sx={{
          width: "100%", //
          aspectRatio: 1,
          maxWidth: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          mb: theme.spacing(2),
        }}
      >
        <img src={HexagonSecondary} width={"100%"} alt="Hexagon" style={{ opacity: 0.8 }} />

        <Box sx={{ position: "absolute", fontSize: "30px", display: "flex", justifyContent: "center", alignItems: "center", color: theme.palette.text.primary, opacity: 0.8 }}>
          {icon}
        </Box>
      </Box>
      <Typography variant="h6" textAlign={"center"} sx={{ textTransform: "uppercase", color: theme.palette.secondary.main }}>
        {name}
      </Typography>
      {status && (
        <Typography
          variant="subtitle2"
          textAlign={"center"}
          sx={{ color: theme.palette.secondary.main, opacity: 0.6 }}
          className={status === "Coming soon" ? "blink-fade-regular" : ""}
        >
          {status}
        </Typography>
      )}
    </Box>
  );
};
